<template>
  <section class="relative">
    <!-- Section background (needs .relative class on parent and next sibling elements) -->
    <div
      class="absolute inset-0 mb-16 bg-gray-100 pointer-events-none"
      aria-hidden="true"
    ></div>
    <div
      class="absolute left-0 right-0 w-px h-20 p-px m-auto transform -translate-y-1/2 bg-gray-200"
    ></div>

    <div class="relative max-w-6xl px-4 mx-auto sm:px-6">
      <div class="pt-12 md:pt-20">
        <!-- Section header -->
        <div class="max-w-3xl pb-12 mx-auto text-center md:pb-16">
          <h1 class="mb-4 h2">Nuestro camino al producto</h1>
          <p class="text-xl text-gray-600">
            Siempre te brindaremos un acompañamiento para la creación de tu
            producto y este proceso es nuestro principal camino a la calidad y
            el éxito.
          </p>
        </div>

        <!-- Top image -->
        <div class="pb-12 md:pb-16">
          <img
            :src="require('@/images/nuestro-camino.png')"
            width="1104"
            height="325"
            alt="nuestro-camino"
          />
        </div>

        <!-- Section content -->
        <div class="md:grid md:grid-cols-12 md:gap-6">
          <!-- Content -->
          <div
            class="max-w-xl mx-auto md:max-w-none md:w-full md:col-span-7 lg:col-span-6 md:mt-6"
          >
            <div class="mb-8 md:pr-4 lg:pr-12 xl:pr-16">
              <h3 class="mb-3 h3">Nuestra fusión de herramientas</h3>
              <p class="text-xl text-gray-600">
                Trabajamos con herramientas de vanguardia y alta calidad para
                todos los productos que entregamos, nuestro amor por ellas esta
                por los cielos.
              </p>
            </div>
            <!-- Tabs buttons -->
            <div class="mb-8 md:mb-0">
              <a
                v-for="(tool, index) in tools"
                :key="index"
                :class="
                  tab !== index
                    ? 'bg-white shadow-md border-gray-200 hover:shadow-lg'
                    : 'bg-gray-200 border-transparent'
                "
                class="flex items-center p-5 mb-3 text-lg transition duration-300 ease-in-out border rounded"
                href="#"
                @click.prevent="tab = index"
              >
                <div>
                  <div class="mb-1 font-bold leading-snug tracking-tight">
                    {{ tool.title }}
                  </div>
                  <div class="text-gray-600">{{ tool.description }}</div>
                </div>
                <div
                  class="flex items-center justify-center flex-shrink-0 w-8 h-8 ml-3"
                >
                  <img :src="tool.image" :alt="tool.image_alt" />
                </div>
              </a>
            </div>
          </div>

          <!-- Tabs items -->
          <div
            class="flex items-center max-w-xl mx-auto mb-8 md:max-w-none md:w-full md:col-span-5 lg:col-span-6 md:mb-0 md:order-1"
            data-aos="zoom-y-out"
          >
            <div
              class="relative flex flex-col items-center text-center lg:text-right"
            >
              <!-- Item 1 -->
              <transition
                enter-active-class="order-first transition duration-700 ease-in-out transform"
                enter-class="translate-y-16 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="absolute transition duration-300 ease-in-out transform"
                leave-class="translate-y-0 opacity-100"
                leave-to-class="-translate-y-16 opacity-0"
              >
                <div class="w-full" v-show="tab === 0">
                  <div class="relative inline-flex flex-col">
                    <img
                      class="mx-auto rounded md:max-w-none"
                      :src="examples[0]"
                      width="500"
                      height="375"
                      alt="Features bg"
                    />
                    <img
                      class="absolute left-0 w-full transform md:max-w-none animate-float"
                      :src="examples[1]"
                      width="500"
                      height="147"
                      alt="Element 01"
                      style="top: 22%"
                    />
                    <img
                      class="absolute left-0 w-full transform md:max-w-none animate-float animation-delay-500"
                      :src="examples[2]"
                      width="500"
                      height="158"
                      alt="Element 02"
                      style="top: 39%"
                    />
                    <img
                      class="absolute bottom-0 left-0 w-full transform md:max-w-none animate-float animation-delay-1000"
                      :src="examples[3]"
                      width="500"
                      height="167"
                      alt="Element 03"
                      style="top: 77%"
                    />
                  </div>
                </div>
              </transition>
              <!-- Item 2 -->
              <transition
                enter-active-class="order-first transition duration-700 ease-in-out transform"
                enter-class="translate-y-16 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="absolute transition duration-300 ease-in-out transform"
                leave-class="translate-y-0 opacity-100"
                leave-to-class="-translate-y-16 opacity-0"
              >
                <div class="w-full" v-show="tab === 1">
                  <div class="relative inline-flex flex-col">
                    <img
                      class="mx-auto rounded md:max-w-none"
                      :src="examples[0]"
                      width="500"
                      height="375"
                      alt="Features bg"
                    />
                    <img
                      class="absolute left-0 w-full transform md:max-w-none animate-float"
                      :src="examples[1]"
                      width="500"
                      height="147"
                      alt="Element 01"
                      style="top: 22%"
                    />
                    <img
                      class="absolute left-0 w-full transform md:max-w-none animate-float animation-delay-500"
                      :src="examples[2]"
                      width="500"
                      height="158"
                      alt="Element 02"
                      style="top: 39%"
                    />
                    <img
                      class="absolute bottom-0 left-0 w-full transform md:max-w-none animate-float animation-delay-1000"
                      :src="examples[3]"
                      width="500"
                      height="167"
                      alt="Element 03"
                      style="top: 77%"
                    />
                  </div>
                </div>
              </transition>
              <!-- Item 3 -->
              <transition
                enter-active-class="order-first transition duration-700 ease-in-out transform"
                enter-class="translate-y-16 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="absolute transition duration-300 ease-in-out transform"
                leave-class="translate-y-0 opacity-100"
                leave-to-class="-translate-y-16 opacity-0"
              >
                <div class="w-full" v-show="tab === 2">
                  <div class="relative inline-flex flex-col">
                    <img
                      class="mx-auto rounded md:max-w-none"
                      :src="examples[0]"
                      width="500"
                      height="375"
                      alt="Features bg"
                    />
                    <img
                      class="absolute left-0 w-full transform md:max-w-none animate-float"
                      :src="examples[1]"
                      width="500"
                      height="147"
                      alt="Element 01"
                      style="top: 22%"
                    />
                    <img
                      class="absolute left-0 w-full transform md:max-w-none animate-float animation-delay-500"
                      :src="examples[2]"
                      width="500"
                      height="158"
                      alt="Element 02"
                      style="top: 39%"
                    />
                    <img
                      class="absolute bottom-0 left-0 w-full transform md:max-w-none animate-float animation-delay-1000"
                      :src="examples[3]"
                      width="500"
                      height="167"
                      alt="Element 03"
                      style="top: 77%"
                    />
                  </div>
                </div>
              </transition>
              <!-- Item 4 -->
              <transition
                enter-active-class="order-first transition duration-700 ease-in-out transform"
                enter-class="translate-y-16 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="absolute transition duration-300 ease-in-out transform"
                leave-class="translate-y-0 opacity-100"
                leave-to-class="-translate-y-16 opacity-0"
              >
                <div class="w-full" v-show="tab === 3">
                  <div class="relative inline-flex flex-col">
                    <img
                      class="mx-auto rounded md:max-w-none"
                      :src="examples[0]"
                      width="500"
                      height="375"
                      alt="backgroud"
                    />
                    <img
                      class="absolute left-0 w-full transform md:max-w-none animate-float"
                      :src="examples[1]"
                      width="500"
                      height="147"
                      alt="Element 01"
                      style="top: 22%"
                    />
                    <img
                      class="absolute left-0 w-full transform md:max-w-none animate-float animation-delay-500"
                      :src="examples[2]"
                      width="500"
                      height="158"
                      alt="Element 02"
                      style="top: 39%"
                    />
                    <img
                      class="absolute bottom-0 left-0 w-full transform md:max-w-none animate-float animation-delay-1000"
                      :src="examples[3]"
                      width="500"
                      height="167"
                      alt="Element 03"
                      style="top: 77%"
                    />
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeaturesHome",
  data() {
    return {
      tab: 0,
      previousTab: 0,
      tools: [
        {
          title: "Un toque creativo para cada producto",
          description:
            "El nacimiento de tu producto se realiza con el apoyo de nuestros cracks en UX & UI.",
          image: require("@/images/tools/ux_ui/icn-ux_ui.svg"),
          image_alt: "ux_ui",
          examples: [
            require("@/images/tools/ux_ui/background.png"),
            require("@/images/tools/ux_ui/1.png"),
            require("@/images/tools/ux_ui/2.png"),
            require("@/images/tools/ux_ui/3.png"),
          ],
        },
        {
          title: "Empezamos desde el lado oscuro",
          description:
            "Para darle vida completa a tu proyecto será siempre necesario el conocimiento de grandes genios del backend.",
          image: require("@/images/tools/backend/icn-backend.svg"),
          image_alt: "backend",
          examples: [
            require("@/images/tools/backend/background.png"),
            require("@/images/tools/backend/1.png"),
            require("@/images/tools/backend/2.png"),
            require("@/images/tools/backend/3.png"),
          ],
        },
        {
          title: "La magia de los estilos",
          description:
            "Nuestro equipo de frontend se mantiene siempre a la vanguardia con lo último en tecnologías para darle el toque mágico final.",
          image: require("@/images/tools/frontend/icn-frontend.svg"),
          image_alt: "frontend",
          examples: [
            require("@/images/tools/frontend/background.png"),
            require("@/images/tools/frontend/1.png"),
            require("@/images/tools/frontend/2.png"),
            require("@/images/tools/frontend/3.png"),
          ],
        },
        {
          title: "Un mundo en tus manos",
          description:
            "No nos quedamos solo en las computadoras, nuestros profesionales en mobile están siempre listos para crear tus proyectos accesibles para todos.",
          image: require("@/images/tools/mobile/icn-mobile.svg"),
          image_alt: "mobile",
          examples: [
            require("@/images/tools/mobile/background.png"),
            require("@/images/tools/mobile/1.png"),
            require("@/images/tools/mobile/2.png"),
            require("@/images/tools/mobile/3.png"),
          ],
        },
      ],
    };
  },
  computed: {
    examples() {
      return this.tools[this.tab].examples;
    },
  },
  // methods: {
  //   showTabContent(index) {
  //     this.previousTab = this.tab;
  //     this.tab = index;
  //   }
  // }
};
</script>