<template>
  <section class="pt-16" id="cta">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="pb-12 md:pb-20">
        <!-- CTA box -->
        <div
          class="px-8 py-10 bg-black rounded shadow-2xl md:py-16 md:px-12"
          data-aos="zoom-y-out"
        >
          <div class="flex flex-col items-center justify-between lg:flex-row">
            <!-- CTA content -->
            <div class="mb-6 text-center lg:mr-24 lg:mb-0 lg:text-left">
              <h3 class="mb-2 text-yellow-500 h3">
                ¿Estas listo para iniciar algo genial?
              </h3>
              <p class="text-lg text-white">
                <span class="opacity-75">
                  Estamos para escucharte y apoyarte en la idea que quieres
                  generar, no dudes en escribirnos a
                </span>
                <a
                  class="font-bold text-yellow-500 underline"
                  href="mailto:hello@kods.io"
                  >hello@kods.io</a
                >
                <span class="opacity-75">
                  para ponernos en contacto contigo.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CtaComponent",
};
</script>
