<template>
  <section class="relative">
    <!-- Section background (needs .relative class on parent and next sibling elements) -->
    <div
      class="absolute inset-0 bg-gray-900 pointer-events-none top-1/2 md:mt-24 lg:mt-0"
      aria-hidden="true"
    ></div>
    <div
      class="absolute bottom-0 left-0 right-0 w-px h-20 p-px m-auto transform translate-y-1/2 bg-gray-200"
    ></div>

    <div class="relative max-w-6xl px-4 mx-auto sm:px-6">
      <div class="py-12 md:py-20">
        <!-- Section header -->
        <div class="max-w-3xl pb-12 mx-auto text-center md:pb-20">
          <h2 class="mb-4 h2">Sabemos lo que necesitas</h2>
          <p class="text-xl text-gray-600">
            Sabemos que necesitas una asesoría para tu proyecto, hacer que tus
            ideas cobren vida y tener un servicio personalizado.
          </p>
        </div>

        <!-- Items -->
        <div
          class="grid items-start max-w-sm gap-6 mx-auto md:grid-cols-2 lg:grid-cols-3 md:max-w-2xl lg:max-w-none"
        >
          <!-- 1st item -->
          <div
            v-for="(service,index) in services"
            :key="index"
            class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl md:h-64"
          >
            <img class="w-16 h-16 p-1 mb-2 -mt-1" :src="service.image" :alt="services.alt_image">
            <h4 class="mb-1 text-xl font-bold leading-snug tracking-tight">
              {{ service.title }}
            </h4>
            <p class="text-center text-gray-600">
              {{ service.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeaturesBlocks",
  data() {
    return {
      services: [
        {
          title: 'Consultorias',
          image: require('@/images/services/consultorias.svg'),
          alt_image: 'consultorias',
          description: 'Estamos para apoyarte en el proceso de mejora para tus productos.'
        },
        {
          title: 'Servicio de UX & UI',
          image: require('@/images/services/ux-ui.svg'),
          alt_image: 'servicio_ux_ui',
          description: 'Te brindamos el kit completo de usabilidad e interfaces que necesitaras para tus ideas.',
        },
        {
          title: 'Desarrollo web',
          image: require('@/images/services/desarrollo_web.svg'),
          alt_image: 'desarrollo_web',
          description: 'Convierte tus ideas en realidad gracias a nuestros genios de la web.',
        },
        {
          title: 'Desarrollo mobile',
          image: require('@/images/services/desarrollo_mobile.svg'),
          alt_image: 'desarrollo_mobile',
          description: ' Tu proyecto estará al alcance de todos y brindamos lo mejor en desarrollo de apps.',
        },
        {
          title: 'Speaking',
          image: require('@/images/services/speaking.svg'),
          alt_image: 'speaking',
          description: 'Estamos listos para apoyarte a generar charlas o talleres afines a productos digitales.',
        },
        {
          title: 'Hosting',
          image: require('@/images/services/hosting.svg'),
          alt_image: 'hosting',
          description: 'Tienes una idea creada pero no sabes como alojarla y ponerla a trabajar completamente ¿A quién llamarás?.',
        }
      ]
    }
  }
};
</script>