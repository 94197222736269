<template>
  <section class="relative">
    <!-- Illustration behind hero content -->
    <div
      class="absolute bottom-0 transform -translate-x-1/2 pointer-events-none left-1/2"
      aria-hidden="true"
    >
      <svg
        width="1360"
        height="578"
        viewBox="0 0 1360 578"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            id="illustration-01"
          >
            <stop stop-color="#FFF" offset="0%" />
            <stop stop-color="#EAEAEA" offset="77.402%" />
            <stop stop-color="#DFDFDF" offset="100%" />
          </linearGradient>
        </defs>
        <g fill="url(#illustration-01)" fill-rule="evenodd">
          <circle cx="1232" cy="128" r="128" />
          <circle cx="155" cy="443" r="64" />
        </g>
      </svg>
    </div>

    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <!-- Hero content -->
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">
        <!-- Section header -->
        <div class="pb-12 text-center md:pb-16">
          <h1
            class="mb-4 text-5xl font-extrabold tracking-tighter md:text-6xl leading-tighter"
            data-aos="zoom-y-out"
          >
            Convierte tu idea en
            <span
              class="text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-orange-500"
              >realidad</span
            >
          </h1>
          <div class="max-w-3xl mx-auto">
            <p
              class="mb-8 text-xl text-gray-600"
              data-aos="zoom-y-out"
              data-aos-delay="150"
            >
              kods está dispuesto a crear tus proyectos tecnológicos como geniales apps, websites innovadores, plataformas interactivas y creativos e innolvidables e-commerces.
            </p>
            <div
              class="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
              data-aos="zoom-y-out"
              data-aos-delay="300"
            >
              <div>
                <a
                  class="w-full mb-4 text-white bg-gradient-to-r from-yellow-500 to-orange-500 btn hover:from-orange-500 hover:to-orange-500 sm:w-auto sm:mb-0"
                  href="#cta"
                  >Conozcamonos más</a
                >
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroHome",
};
</script>